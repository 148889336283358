.name-entry-form {
  max-width: 500px;
  width: 100%;
  margin: auto;
  padding: 30px 0 10px 0;
  vertical-align: middle;
  font-size: 1.5rem;

  input {
    font-size: 1.5rem;
    padding-right: 10px;
    vertical-align: middle;
  }

  button {
    background: none;
    border: none;
    padding-left: 10px;
    vertical-align: middle;
  }

  svg {
    vertical-align: middle;
    color: black;
  }

  .form-hi {
    vertical-align: middle;
  }

  div.display-name {
    font-size: 1.5rem;
  }

  div.display-name span {
    background-color: lightgray;
  }
}

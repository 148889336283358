.Image {
  position: relative;
  display: inline-block; /* <= shrinks container to image size */
}

.complete {
  animation: fadeOut 1s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
}

.Image img {
  /* <= optional, for responsiveness */
  display: block;
  /* max-width: 100%;
     height: auto; */
  /* width: 100px;
     height: 100px; */
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
  /* opacity: 0.5; */
  object-fit: cover;
}

.Image .overlay {
  position: absolute;
  top: 0;
  left: 0;
  --size: calc(100% - 1rem);
  height: var(--size);
  width: var(--size);
  margin: 0.5rem;
  /* opacity: 0.9; */
}

.Image svg.CircularProgressbar {
  /* position: absolute;
    top: 0;
    left: 0;
    --size: calc(100% - 1rem);
    height: var(--size);
    width: var(--size);
    margin: 0.5rem; */
}

.Image svg.checkmark {
  /* position: absolute;
    top: 0;
    left: 0;
    --size: calc(100% - 1rem);
    height: 66px;
    width: 66px;
    margin: 17px; */
  animation: fadeIn 1s;
  /* opacity: 0.9; */
}

.Image svg.retry {
  /* position: absolute;
    top: 0;
    left: 0;
    height: 84px;
    width: 84px;
    margin: 8px; */
  animation: fadeIn 1s;
  transition: transform 150ms ease-in-out;
}

.Image svg.retry:hover {
  transform: rotate(45deg);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

html,
body {
  height: 100%;
}

input#fileElem {
  display: none;
}

#drop_zone_box {
  border: 4px dashed gray;
  width: 1fr;
  margin: 1.5rem 2rem;
  /* height: 5rem; */
  height: 30%;
  border-radius: 25px;
  user-select: none;
}

:not(.hover) #drop_zone_box:hover {
  background-color: #eeeeee;
}

:not(.hover) #drop_zone_box:hover svg.UploadIcon {
  fill: blue;
}

#drop_zone_box > p {
  padding: 1rem;
}

#drop_zone_full {
  box-sizing: border-box;
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
  pointer-events: none;
}

body.hover {
  background-color: lightgray;
}

#drop_zone_box.hover {
  border-color: #00000000; /* transparent */
}

#drop_zone_full.hover {
  border: 4px dashed gray;
  border-radius: 25px;
}
